import { Heading, Text } from "@storyofams/react-ui"
import { NextSeo } from "next-seo"
import { FormattedMessage } from "react-intl"
import { Button } from "~components/common/Button"
import { Container } from "~components/common/Container"

const Error = ({ statusCode = "404" }) => {
    const text = "We could not find the page you were looking for."
    return (
        <>
            <NextSeo title={statusCode} description={text} />
            <Container minHeight="100vh" bgColor={"banana" as any}>
                <Heading
                    textAlign="center"
                    color={"aloe" as any}
                    fontSize={[5, 7]}
                    lineHeight="heading"
                    as="h1"
                >
                    {statusCode}
                </Heading>
                <Text
                    textAlign="center"
                    color={"black" as any}
                    fontSize={[2, 2.5]}
                    lineHeight="high"
                    maxWidth="480px"
                >
                    <FormattedMessage
                        id="pages._error.404"
                        defaultMessage={text}
                    />
                </Text>
                <Button to="/" variant="fig">
                    <FormattedMessage
                        id="pages._error.takeMeHome"
                        defaultMessage="Take me home"
                    />
                </Button>
            </Container>
        </>
    )
}

export default Error
